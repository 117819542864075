<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Shops</h3>
          <div class="justify-content-between">
            <router-link :to="{name: 'admin_create_shop'}" class="ps-btn btn-sm"><i class="icon-plus"></i> Create Shop</router-link>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Category</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="shop in shops" :key="shop.id">
              <td>{{ shop.id }}</td>
              <td><span class="text-primary">{{ shop.name }}</span></td>
              <td>{{ shop.category.name }}</td>
              <td>
                <span v-if="shop.status.name === 'pending'" class="text-warning">{{ shop.status.label }} </span>
                <span v-if="shop.status.name === 'approved'" class="text-success">{{ shop.status.label }} </span>
                <span v-if="shop.status.name === 'deleted'" class="text-danger">{{ shop.status.label }} </span>
              </td>
              <td>
                <router-link :to="{name: 'admin_edit_shop', params: {id: shop.id}}">
                  <i class="icon-pencil"></i>
                </router-link>
<!--                <a class="ml-3" href="#" @click="deleteProduct(product.id)"><i class="fa fa-trash" /></a>-->
              </td>
            </tr>
            </tbody>
            <tfoot>
                <tr v-if="(!meta || meta.total === 0) && !isLoading">
                  <td colspan="7" class="text-center m-4 text-danger p-4">No shops available</td>
                </tr>
                <tr v-else-if="isLoading">
                  <td colspan="7" class="text-center m-4 text-danger">Loading shops...</td>
                </tr>
                <tr v-else>
                  <td class="text-right" colspan="7">
                    <ul class="pagination text-right">
                      <li v-on:click="goToPrevPage()" v-if="hasPrevPage">
                        <a href="#">Prev Page<i class="icon-chevron-left"></i></a>
                      </li>
                      <li class="active"><a href="#">Page {{ pageNumber }}</a></li>
                      <!--                      <li><a href="#">3</a></li>-->
                      <li v-on:click="goToNextPage()" v-if="hasNextPage">
                        <a href="#">Next Page<i class="icon-chevron-right"></i></a>
                      </li>
                    </ul>
                  </td>
                </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "List",
  data() {
    return {
      shops: [],
      meta: null,
      isLoading: false,
      isDeleting: false,
      links: {},
      url: null,
      hasNextPage: false,
      nextPage: null,
      hasPrevPage: false,
      previousPage: null,
      pageNumber: 1
    }
  },
  mounted() {
    this.getShops()
  },
  methods: {
    getShops() {
      this.isLoading = true;
      let url = 'shops';
      if (this.url) {
        url = this.url;
      }

      axios.get(url).then((response) => {
          this.shops = response.data.data;
          this.meta = response.data.meta;
          this.links = response.data.links;
          this.count = response.data.meta.total;
          this.pageNumber = response.data.meta.current_page;
          this.handleNextPage(response.data.links);
          this.handlePrevPage(response.data.links);
          this.isLoading = false;
      }).catch((error) => {
         this.isLoading = false;
      });
    },

    deleteProduct(id) {
      if (confirm('Are you sure you want to delete this product')) {
        this.isDeleting = true;
        axios.delete('products/' + id).then((response) => {
          this.getProducts()
          this.isDeleting = false;
        }).catch((error) => {
          this.isDeleting = false;
        });
      }
    },

    scrollTop() {
      let element = document.getElementsByClassName("table-responsive");
      if (element.length > 0) {
        const y = element[0].getBoundingClientRect().top + window.scrollY - 95;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
    },

    goToNextPage: function()
    {
      this.url = this.nextPage;
      if (this.url) {
        this.getShops()
      }
      this.scrollTop();
    },

    goToPrevPage: function()
    {
      this.url = this.prevPage;
      if (this.url) {
        this.getShops()
      }
      this.scrollTop();
    },

    handleNextPage(links)
    {
      if (links.next) {
        this.hasNextPage = true;
        this.nextPage = links.next;
      } else {
        this.hasNextPage = false
      }
    },

    handlePrevPage(links)
    {
      if (links.prev) {
        this.hasPrevPage = true;
        this.prevPage = links.prev;
      } else {
        this.hasPrevPage = false
      }
    }

  }
}
</script>

<style scoped>

</style>